import { useState, useEffect } from 'react'
import React from 'react';
import logo from './logo.svg';
import './App.css';

import {useRef} from 'react';
import './App.css';
// Replace these with the paths to your images
import { sequence } from '0xsequence';
import { ethers } from 'ethers';
import abi from './abi/abi.js'
import dial from './download_dial_grey.png'

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

//@ts-ignore
// import gsap from 'https://cdn.skypack.dev/gsap@3.7.0'

import { gsap } from 'gsap';
import {InertiaPlugin} from './esm/InertiaPlugin'
import { Draggable, MotionPathPlugin } from 'gsap/all';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';

const library = {
  0: '🃟',
  1: '⚚',
  2: '♕',
  3: '⚘',
  4: '♖',
  5: '♔',
  6: '☂',
  7: '♘',
  8: '☮',
  9: '☯',
  10: '☸',
  11: '⚖',
  12: '♱',
  13: '☠',
  14: '♻',
  15: '☢',
  16: '☖',
  17: '★',
  18: '☽',
  19: '☼',
  20: '⚱',
  21: '⚬',
  22: '∅',
  23: 'RED',
  24: 'ORANGE',
  25: 'YELLOW',
  26: 'GREEN',
  27: 'CYAN',
  28: '◨',
  29: '❍',
  30: '☉',
  31: '☿',
  32: '♀',
  33: '♁',
  34: '♂',
  35: '⚳',
  36: '♃',
  37: '♄',
  38: '♅',
  39: '♆',
  40: '♇',
  41: '⚀',
  42: 'Birch 𐂷 BEITH',
  43: 'Rowan 𐂷 LUIS',
  44: 'Alder 𐂷 FEARN',
  45: 'Willow 𐂷 SAILLE',
  46: 'Ash 𐂷 NUIN',
  47: 'Hawthon 𐂷 HUATHE',
  48: 'Oak 𐂷 DUIR',
  49: 'Holly 𐂷 TINNE',
  50: 'Hazel 𐂷 COLL',
  51: 'Apple 𐂷 QUERT',
  52: 'Vine 𐂷 MUIN',
  53: 'Ivy 𐂷 GORT',
  54: 'Reed 𐂷 NGETAL',
  55: 'Blackthorn 𐂷 STRAIF',
  56: 'Elder 𐂷 RUIS',
  57: 'Silver Fir 𐂷 AILIM',
  58: 'Furze 𐂷 OHN',
  59: 'Heather 𐂷 UR',
  60: 'Poplar 𐂷 EADHA',
  61: 'Yew 𐂷 IOHO',
  62: 'The Grove 𐂷 KOAD',
  63: 'Spindle 𐂷 OIR',
  64: 'Honeysuckle 𐂷 UNILEAND',
  65: 'Beech 𐂷 PHAGOS',
  66: 'The Sea 𐂷 MOR',
  67: '⚁',
  68: 'The Self ᛗ MANNAZ',
  69: 'Partnership ᚷ GEBO',
  70: 'Signals ᚫ ANSUZ',
  71: 'Seperation ᛟ OTHILA',
  72: 'Strength ᚢ URUZ',
  73: 'Initiatian ᛈ PERTH',
  74: 'Constraint ᚾ NAUTHIZ',
  75: 'Fertility ᛝ INGUZ',
  76: 'Defense ᛇ EIHWAZ',
  77: 'Protection ᛉ ALGIZ',
  78: 'Posessions ᛓ FEHU',
  79: 'Joy ᚹ WUNJO',
  80: 'Harvest ᛃ JERA',
  81: 'Opening ᚲ KANO',
  82: 'Warrior ᛏ TEIWAZ',
  83: 'Growth ᛒ BERKANA',
  84: 'Movement ᛖ EHWAZ',
  85: 'Flow ᛐ LAGUZ',
  86: 'Disruption ᚺ HAGALAZ',
  87: 'Journey ᚱ RAIDO',
  88: 'Gateway ᚦ THURISAZ',
  89: 'Breakthrough ᛞ DAGAZ',
  90: 'Standstill ᛁ ISA',
  91: 'Wholeness ᛲ SOWELU',
  92: 'Unknowable  ODIN',
  93: '⚂',
  94: '🜁',
  95: '🜂',
  96: '🜃',
  97: '🜄',
  98: '⚃',
  99: '䷀',
  100: '䷁',
  101: '䷂',
  102: '䷃',
  103: '䷄',
  104: '䷅',
  105: '䷆',
  106: '䷇',
  107: '䷈',
  108: '䷉',
  109: '䷊',
  110: '䷋',
  111: '䷌',
  112: '䷍',
  113: '䷎',
  114: '䷏',
  115: '䷐',
  116: '䷑',
  117: '䷒',
  118: '䷓',
  119: '䷔',
  120: '䷕',
  121: '䷖',
  122: '䷗',
  123: '䷘', // 25
  124: '䷙',
  125: '䷚',
  126: '䷛',
  127: '䷜',
  128: '䷝',
  129: '䷞',
  130: '䷟',
  131: '䷠',
  132: '䷡',
  133: '䷢',
  134: '䷣',
  135: '䷤',
  136: '䷥',
  137: '䷦',
  138: '䷧', // 40
  139: '䷨',
  140: '䷩',
  141: '䷪',
  142: '䷫',
  143: '䷬',
  144: '䷭',
  145: '䷮',
  146: '䷯',
  147: '䷰',
  148: '䷱', // 50
  149: '䷲',
  150: '䷳',
  151: '䷴',
  152: '䷵',
  153: '䷶',
  154: '䷷',
  155: '䷸',
  156: '䷹', /// 58
  157: '䷺',
  158: '䷻',
  159: '䷼',
  160: '䷽',
  161: '䷾',
  162: '䷿',
  163: '¤',
  164: '🃕',
  165: '🂢',
  166: '🃉',
  167: '🂾',
  168: '🃃',
  169: '🂣',
  170: '🃋',
  171: '🂶',
  172: '🃍',
  173: '🂮',
  174: '🃞',
  175: '🂪',
  176: 'MOONSTONE',
  177: 'LAPIS_LAZULI',
  178: 'FLOWER_AGATE',
  179: 'MOSS_AGATE',
  180: 'SHAKTI',
  181: 'SUNSTONE',
  182: 'HEMATITE',
  183: 'AQUAMARINE',
  184: 'MALACHITE',
  185: 'ARAGONITE',
  186: 'BLACK_TOURMALINE',
  187: 'TOURQUISE',
  188: 'CALCITE',
  189: 'ROSE_QUARTZ',
  190: 'FLOURITE',
  191: 'PINK_AMETHYST',
  192: 'SMOKY_AMAZONITE',
  193: 'CITRINE',
  194: 'PEACH_SELENITE',
  195: 'SUPER_SEVEN',
  196: 'LABRADORITE',
  197: 'LARIMAR',
  198: 'LACE_AGATE',
  199: 'PYRITE',
  200: 'RED_JASPER',
  201: 'APOPHYLLITE',
  202: 'OPAL',
  203: 'EMERALD',
  204: 'HERKIMER_DIAMOND',
  205: 'AZURITE',
  206: 'AMMONITE',
  207: 'DESERT_JASPER',
  208: 'SPIRIT_QUARTZ',
  209: '⚄',
  210: '☌',
  211: '⚯',
  212: '□',
  213: '♈︎',
  214: '⚹',
  215: '⚯',
  216: '□',
  217: 'WOOD',
  218: 'METAL',
  219: '⇠',
  220: '⇡',
  221: '⇢',
  222: '⇣',
  223: '꩜',
  224: '❀',
  225: '𑗘',
  226: '♡',
  227: '▲',
  228: 'FRIDAY',
  229: '⚅',
  230: 'WOLF',
  231: 'CAT',
  232: 'ROOSTER',
  233: 'COW',
  234: 'BUFFALO',
  235: 'SPIDER',
  236: 'HORSE',
  237: 'SNAKE',
  238: 'FISH',
  239: 'FOX',
  240: 'BAT',
  241: 'MONKEY',
  242: 'TURTLE',
  243: 'BIRD',
  244: 'GOAT',
  245: 'MALKUTH',
  246: 'YESOD',
  247: 'HOD',
  248: 'NETZACH',
  249: 'TIPARETH',
  250: 'GEBURH',
  251: 'CHESED',
  252: 'DAATH',
  253: 'BINAH',
  254: 'CHOKMAH',
  255: 'KETER'
}

// Helper function to generate polygon points
const generatePolygonPointsAndTexts = (sides: any, radius: any, offsetX: any, offsetY: any) => {
  let points = '';
  let textPositions = [];
  for (let i = 0; i < sides; i++) {
      // Calculate angle for this vertex
      const angle = (2 * Math.PI * i) / sides;
      // Calculate x and y coordinates for the polygon points
      const x = radius + radius * Math.cos(angle);
      const y = radius + radius * Math.sin(angle);
      points += `${x},${y} `;

      // Calculate text position, offset by 8 units from each point
      const textX = x + 10 * Math.cos(angle) + offsetX - 35;
      const textY = y + 10 * Math.sin(angle) + offsetY-28;
      textPositions.push({ x: textX, y: textY });
  }
  return { points: points.trim(), textPositions };
};

const Shape = ({ sides }: any) => {
    const size = 100; // Size of the SVG container
    const stroke = "purple"; // Color of the shape
    const strokeWidth = "2"; // Stroke width
    // const fill = "purple"; // Fill color
    const radius = 30; // Radius for polygons
    const translate = "translate(5, 500)"; 

    // Adjustments for text positioning
    const offsetX = size / 2;
    const offsetY = size / 2;

    let skyValues: any = ['LIGHT',"MIND",'DARK','METAL','LIGHT','LIGHT','DARK','METAL','DARK', '♣']
    
    // Render based on the number of sides
    if (sides === 1) {
        // Render a dot
        return (
          <>
                <circle cx={size / 2} cy={size / 2} r="2.5" fill={stroke} transform={translate} />
                <text fill='purple' x={offsetX - 8} y={offsetY - 8} font-family="Arial" font-size="10" transform={translate}>{skyValues[0]}</text>
                </>
        );
    } else if (sides === 2) {

        // Render a line
        return (
          <>
                <line x1="40" y1="50" x2="60" y2="50" stroke={stroke} strokeWidth={strokeWidth} transform={translate} />
                <text fill='purple' x={offsetX - 40} y={50 + 100 + 3+400} font-family="Arial" font-size="10">{skyValues[getDayOfYear()%skyValues.length]}</text> {/* Left of the line */}
                <text fill='purple' x={offsetX + 22} y={50 + 100 + 3+400} font-family="Arial" font-size="10">{skyValues[(getDayOfYear()+1)%skyValues.length]}</text> {/* Left of the line */}
                {/* <text x={60 + 100 + 5} y={50 + 150 + 3} font-family="Arial" font-size="10">8</text> Right of the line */}
                </>
        );
    } else {
      const translate = "translate(-22, 500)"; 
        const dayOffset = 15
        // Render a polygon
        const { points, textPositions } = generatePolygonPointsAndTexts(sides, radius, offsetX, offsetY);

        return (
          <>
                <polygon points={points} fill={'none'} stroke={stroke} strokeWidth={strokeWidth} transform={`${translate} translate(${size / 2 - radius}, ${size / 2 - radius})`} />
                {textPositions.map((pos, index) => (
                    <text key={index} x={pos.x} y={pos.y} font-family="Arial" font-size="10" fill='purple' transform={translate}>{skyValues[(dayOffset+getDayOfYear()+index)%skyValues.length]}</text>
                ))}
                </>
        );
    }
};

function wrapAroundValues(object: any, offset: any) {
  const values = Object.values(object);
  const wrappedValues = [];

  // Starting index based on offset
  const startIndex = offset % values.length;

  // Wrap around the values starting from startIndex
  for (let i = 0; i < values.length; i++) {
    const currentIndex = (startIndex + i) % values.length;
    wrappedValues.push(values[currentIndex]);
  }

  return wrappedValues;
}

function wrapAroundPrismHalf(values: any, offset: any) {
  // const values = Object.values(object);
  const wrappedValues = [];

  // Starting index based on offset
  const startIndex = offset % values.length;

  // Wrap around the values starting from startIndex
  for (let i = 0; i < values.length; i++) {
    const currentIndex = (startIndex + i) % values.length;
    wrappedValues.push(values[currentIndex]);
  }

  return wrappedValues;
}

function wrapAroundArray(array: any, offset: any) {
  const wrappedArray = [];
  const arrayLength = array.length;

  // Calculate the starting index based on the offset
  const startIndex = offset % arrayLength;

  // Wrap around the array starting from startIndex
  for (let i = 0; i < arrayLength; i++) {
    const currentIndex = (startIndex + i) % arrayLength;
    wrappedArray.push(array[currentIndex]);
  }

  return wrappedArray;
}


function getDayOfYear() {
  const now: any = new Date();
  const start: any = new Date(now.getFullYear(), 0, 1); // January 1st of current year
  const diff = now - start; // difference in milliseconds
  const oneDay = 1000 * 60 * 60 * 24; // milliseconds in one day
  const day = Math.floor(diff / oneDay);
  return day + 1; // +1 because we start counting from 1
}

function TwoFiftySix() {
  const [counter, _] = useState(0)
  const [logo, setLogo] = useState('')
  const [whl, setWhl] = useState('')
  const [pat, setPat] = useState('')
  const [hermetic, setHermetic] = useState('')
  const [talisLoop, setTalis] = useState('')
  const [skySet, setSkySet] = useState('')
  const [outerLoop, setOuterLoop] = useState<any>('')
  const [innerLoop, setInnerLoop] = useState<any>('')
  const [innerHermeticLoop, setInnerHermeticLoop] = useState<any>('')
  const [online, isOnline] = useState<any>(false)

  useEffect(() => {
  //   setTimeout(async () => {
  //   //   const listOfUsers = ['12D3KooWQ7P4MB1MStsUDBFn6XNv8SpteBC4V5YvW1qYr4PJ1ryw', '12D3KooWQ3MEUwyDJw29W4kURS5ztepThvAE5LkivNykheDJeJRr']
  //   //   let count = 0;
  //   //   for(let i = 0; i < listOfUsers.length; i++){
  //   //     const res = await fetch(listOfUsers[i])

  //   //     if(res.status == 200){
  //   //       count++
  //   //     }
  //   //   }
  //   //   if(count>1) isOnline(true)

  //   // }, 0)
  isOnline(true)
    
  }, [])
  useEffect(() => {
    const entries = Object.entries(library);
    let talis = '';
    let max = 0;
      // document.getElementById('blueTextPath')!.setAttribute('startOffset', blueOffset + '%');
    const offset = getDayOfYear() +  Math.floor(256/2)+-1+counter
    const result = wrapAroundValues(library, offset);
  
    // Find the maximum length
    entries.forEach(([_, value], index: any) => {
      if (value.length > max) {
        max = value.length;
      }
      if(offset%256 == index+129-2){
        setLogo(value)
      }
    });

  
    // Center each string in a slot of 'max' width
    result.forEach((value: any, _: any) => {
      // if(value == '䷆'){
      //   console.log(index)
      //   setLogo(value)
      // }
      const padding = max - value.length;
      const paddingLeft = Math.floor(padding / 2);
      const paddingRight = padding - paddingLeft;
      talis += '_'.repeat(paddingLeft) + value + '_'.repeat(paddingRight);
    });
  
    setTalis(talis);

    let sky = '';
    let skyMax = 0;

    const offsetSky = getDayOfYear()+3

    let skyValues: any = ['METAL','LIGHT',"MIND",'DARK','LIGHT','LIGHT','DARK','METAL','DARK', '♡']
    
    const resultSky = wrapAroundPrismHalf(skyValues, offsetSky);

    resultSky.forEach((value: any, _: any) => {
      if (value.length > skyMax) {
        skyMax = value.length;
      }
      // if(offset%256 == index+129){
      //   setLogo(value)
      // }
    });


    console.log(resultSky)
    // Center each string in a slot of 'max' width
    resultSky.map((value: any, _: any) => {
      const padding = (skyMax - value.length)*30;
      const paddingLeft = Math.floor(padding / 2);
      const paddingRight = padding - paddingLeft;
      sky += '_'.repeat(paddingLeft) + value + '_'.repeat(paddingRight);
      // if(index == 129-2){
      //   console.log(index)
      //   setLogo(value)
      // }
    });
  
    setSkySet(sky);
    console.log(skySet)

  }, [counter, skySet]);

  useEffect(() => {
    const entries = Object.entries(library);
    let talis = '';
    let max = 0;
  
    // Find the maximum length
    const values = ['☿','♂','☿','🜂','♄','🜃','♃','♀','♅','♆','♄','☽','♇','🜄','☉','🜁']

    values.forEach((value, index: any) => {
      if(getDayOfYear()%values.length == index){
        setWhl(value)
      }
    });

    entries.forEach(([_, value]) => {
      if (value.length > max) {
        max = value.length;
      }
    });

    const offset = getDayOfYear() +  Math.floor(256/2)+1+counter

    const result = wrapAroundArray(values, offset);
    result.forEach((value) => {

      const padding = max - value.length+1;
      const paddingLeft = Math.floor(padding / 2);
      const paddingRight = padding - paddingLeft;
      talis += '_'.repeat(paddingLeft) + value + '_'.repeat(paddingRight);
    });
    setOuterLoop(talis);
  }, [counter]);

  useEffect(() => {
    const entries = Object.entries(library);
    let talis = '';
    let max = 0;
  
    // Find the maximum length
    entries.forEach(([_, value]) => {
      if (value.length > max) {
        max = value.length;
      }
    });

    // const values = ["zod","nec","bud","wes","sev","per","sut","let","ful","pen","syt","dur","wep","ser","wyl","sun","ryp","syx"]
    // const values = ["dyr","nup","heb","peg","lup","dep","dys","put","lug","hec","ryt","tyv","syd","nex","lun","mep","lut","sep"]
    // const values = ["pes","del","sul","ped","tem","led","tul","met","wen","byn","hex","feb","pyl","dul","het","mev","rut","tyl",]
    // const values = ["wyd","tep","bes","dex","sef","wyc","bur","der","nep","pur","rys","reb","den","nut","sub","pet","rul","syn"]
    // const values = ["wyd","tep","bes","dex","sef","wyc","bur","der","nep","pur","rys","reb","den","nut","sub","pet","rul","syn"]
    const values = ["doz","mar","bin","wan","sam","lit","sig","hid","fid","lis","sog","dir","wac","sab","wis","sib","rig","sol","dop"]
    const offset = getDayOfYear() +  Math.floor(256/2)-1+ counter
    
    values.forEach((value, index: any) => {
      if(getDayOfYear()%values.length == index){
        setPat(value)
      }
    });

    const result = wrapAroundArray(values, offset);

    result.forEach((value) => {
      const padding = max - value.length;
      const paddingLeft = Math.floor(padding / 2);
      const paddingRight = padding - paddingLeft;
      talis += '_'.repeat(paddingLeft) + value + '_'.repeat(paddingRight);
    });
    setInnerLoop(talis);
  }, [counter]);

  useEffect(() => {
    console.log(hermetic)
  }, [hermetic])
  useEffect(() => {
    const entries = Object.entries(library);
    let talis = '';
    let max = 0;
  
    // Find the maximum length
    // const values = ['☿','♂','☿','🜂','♄','🜃','♃','♀','♅','♆','♄','☽','♇','🜄','☉','🜁']
    // const values = ['🜃',['☿','♋︎','♄'],'♄',['☿','♍︎',],'♋︎','🜄',['🜁','🜄'],['🜄','♉︎','🜂','☽'],['🜂','🜄'],['♍︎','☉','♄'], '🜃',['☿','♋︎','♄'],'♄',['☿','♍︎',],'♋︎','🜄',['🜁','🜄'],['🜄','♉︎','🜂','☽'],['🜂','🜄'],['♍︎','☉','♄']]
    const values = ['🜃','☿','♋︎','♄','♄','☿','♍︎','♋︎','🜄','🜁','🜄','🜄','♉︎','🜂','☽','🜂','🜄','♍︎','☉','♄', '🜃','☿','♋︎','♄','♄','☿','♍︎','♋︎','🜄','🜁','🜄','🜄','♉︎','🜂','☽','🜂','🜄','♍︎','☉','♄']

    // const values = ["wyd","tep","bes","dex","sef","wyc","bur","der","nep","pur","rys","reb","den","nut","sub","pet","rul","syn"]
    const day = getDayOfYear()
    // const offset = 0
    // const result0 = wrapAroundArray(values, day+offset);

    function isArrayAndNotNull(value: any) {
      return Array.isArray(value) && value !== null;
    }


    const wrappedValues = wrapAroundValues(values, day+16);

    
    // Logic to select and handle the value based on the day
    const selectedValue: any = wrappedValues[0]; // Always selects the first value after wrapping, which corresponds to current day
    console.log(selectedValue)
    if (isArrayAndNotNull(selectedValue)) {
      // If the selected value is an array, choose a sub-value based on day mod array length
      const innerIndex = day % selectedValue.length;
      const tempValue = selectedValue[innerIndex];
      setHermetic(tempValue);
    } else {
      // If the selected value is not an array
      setHermetic(selectedValue);
    }

  //   // for(value: any, index: any) => {
  //     for(let index = 0; index < result0.length; index++) {
  //       const value = result0[index]
  //       console.log(value)
  //       console.log(isArrayAndNotNull(value))
  //       if((day+offset)%values.length == index && !isArrayAndNotNull(value) || (isElement(value) && (day+offset)%values.length == index)){
  //       console.log(index)
  //       console.log(index)
  //       console.log(value)
  //       setHermetic(value)
  //       break
  //     } else if (day%values.length == index && isArrayAndNotNull(value)){
  //       console.log(index)
  //       console.log(value)
  //       console.log(value.length)
  //       const tempValue = value[day%value.length]
  //       console.log(tempValue)
  //       setHermetic(tempValue)
  //       break;
  //     }
  // }

    entries.forEach(([_, value]) => {
      if (value.length > max) {
        max = value.length;
      }
    });

      const result = wrapAroundArray(values, day+7);
      result.forEach((value) => {
        // console.log(value)
        // if(value.length <= 1){
  
          const padding = max - value.length;
          const paddingLeft = Math.floor(padding / 2);
          const paddingRight = padding - paddingLeft;
          talis += '_'.repeat(paddingLeft) + value + '_'.repeat(paddingRight);
        // }
        // else {
        //   const tempValue = value[day%value.length]
        //   const padding = max - tempValue.length;
        //   const paddingLeft = Math.floor(padding / 2);
        //   const paddingRight = padding - paddingLeft;
        //   talis += '_'.repeat(paddingLeft) + tempValue + '_'.repeat(paddingRight);
        // }
  
      });
      setInnerHermeticLoop(talis);
  }, [counter, innerHermeticLoop]);

  const random = () => {
    (() => {
      // const entries = Object.entries(library);
    let talis = '';
    let max = 0;
      // document.getElementById('blueTextPath')!.setAttribute('startOffset', blueOffset + '%');
    const offset = getDayOfYear() +  Math.floor(Math.random()*1000)-1
    const result = wrapAroundValues(library, offset);
  
    // Find the maximum length
    // const offset = getDayOfYear() +  Math.floor(256/2)-1+counter
    // const result = wrapAroundValues(library, offset);
  
    // Find the maximum length
    result.forEach((value: any, _: any) => {
      if (value.length > max) {
        max = value.length;
      }
    });
  
    // Center each string in a slot of 'max' width
    result.forEach((value: any, index: any) => {

      const padding = max - value.length;
      const paddingLeft = Math.floor(padding / 2);
      const paddingRight = padding - paddingLeft;
      talis += '_'.repeat(paddingLeft) + value + '_'.repeat(paddingRight);
      if(index == 129-2){
        // console.log(index)
        setLogo(value)
      }
    });

    
    })();

    (() => {
      const entries = Object.entries(library);
    let talis = '';
    let max = 0;
  
    // Find the maximum length
    const values = ['☿','♂','☿','🜂','♄','🜃','♃','♀','♅','♆','♄','☽','♇','🜄','☉','🜁']
    const offset = getDayOfYear() +  Math.floor(Math.random()*1000)+1

    values.forEach((value, index: any) => {
      if(offset%values.length == index+1){
        setWhl(value)
      }
    });

    entries.forEach(([_, value]) => {
      if (value.length > max) {
        max = value.length;
      }
    });


    const result = wrapAroundArray(values, offset);
    result.forEach((value) => {

      const padding = max - value.length;
      const paddingLeft = Math.floor(padding / 2);
      const paddingRight = padding - paddingLeft;
      talis += '_'.repeat(paddingLeft) + value + '_'.repeat(paddingRight);
    });
    setOuterLoop(talis);
    })();

    (() => {
      const entries = Object.entries(library);
    let talis = '';
    let max = 0;
  
    // Find the maximum length
    entries.forEach(([_, value]) => {
      if (value.length > max) {
        max = value.length;
      }
    });

    // const values = ["zod","nec","bud","wes","sev","per","sut","let","ful","pen","syt","dur","wep","ser","wyl","sun","ryp","syx"]
    // const values = ["dyr","nup","heb","peg","lup","dep","dys","put","lug","hec","ryt","tyv","syd","nex","lun","mep","lut","sep"]
    // const values = ["pes","del","sul","ped","tem","led","tul","met","wen","byn","hex","feb","pyl","dul","het","mev","rut","tyl",]
    const values = ["wyd","tep","bes","dex","sef","wyc","bur","der","nep","pur","rys","reb","den","nut","sub","pet","rul","syn"]
    
    const offset = getDayOfYear() +  Math.floor(Math.random()*1000)-1
    
    values.forEach((value, index: any) => {
      if(offset%values.length == index+1){
        setPat(value)
      }
    });

    const result = wrapAroundArray(values, offset);

    result.forEach((value) => {
      const padding = max - value.length;
      const paddingLeft = Math.floor(padding / 2);
      const paddingRight = padding - paddingLeft;
      talis += '_'.repeat(paddingLeft) + value + '_'.repeat(paddingRight);
    });
    setInnerLoop(talis);
    })();
  }

  const [on, setOn] = useState(true)

  useEffect(() => {
      const day = getDayOfYear()
      day >= 24 && day <= 58
      ? 
        setOn(false)
      :
      day > 58 && day <= 167
      ?
        setOn(false)
      :
      day > 167 && day <= 256
      ?
        setOn(false) 
      :
        setOn(true)
  }, [])

  

  return (
    <>
      <div style={{position: 'fixed', top: '30px', left: '30px'}}>
        <span style={{color: 'purple'}}>∵&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style={{color: 'blue'}}>{logo}</span><span style={{color: 'red', marginBottom: '-10px',fontSize:'25px'}}>{whl}</span>{ on ? <span style={{color: 'black'}}>{pat}</span> :<span style={{color: 'limegreen'}}>{hermetic}</span>  }
      </div>

      <div className="message-mobile">
        dials are not supported on mobile currently
      </div>

      <div className="hide-on-mobile">
      <svg width="6000" height="6000"style={{marginLeft: '520px'}}>
        <path id="outerCirclePath" fill="none" d="M200,610 m-100,0 a 600,600 0 1,0 1200,0 a 600,600 0 1,0 -1200,0" />
        <text fontSize="20" fill="red">
            <textPath id="outerTextPath" xlinkHref="#outerCirclePath">
                {outerLoop}
            </textPath>
        </text>
        <path id="blueCirclePath" fill="none" d="M-14480,610 m-40,0 a 7300,7300 0 1,0 14600,0 a 7300,7300 0 1,0 -14600,0" />
        <text fontSize="15" fill="blue">
            <textPath id="blueTextPath" xlinkHref="#blueCirclePath">
                {talisLoop}
            </textPath>
        </text>
        <Shape sides={3}/>
        {/* <Shape sides={2}/> */}
        {/* <Shape sides={3}/> */}
        {/* <Shape sides={4}/> */}
        {/* <Shape sides={5}/> */}
        {online ? <><path id="purpleCirclePath" style={{marginLeft: '-10px'}} fill="none" stroke="purple"
          d="M600,600 m -1130,0 a 200,200 0 1,0 600,0 a 300,300 0 1,0 -600,0" />
        <text fontSize="15" fill="purple">
            <textPath id="purpleTextPath" xlinkHref="#purpleCirclePath">
                {skySet}
            </textPath>
        </text></> : null }
        { on ? <path id="innerCirclePath" fill="none" d="M170,610 m-50,0 a 500,500 0 1,0 1000,0 a 500,500 0 1,0 -1000,0" /> : null}
        { on ? <text fontSize="15" fill="black">
            <textPath id="innerTextPath" xlinkHref="#innerCirclePath">
                {innerLoop}
            </textPath>
  </text> : null }
  { !on ? <path id="innerCirclePath" fill="none" d="M170,610 m-50,0 a 300,500 0 1,0 1000,0 a 500,500 0 1,0 -1000,0" /> : null}

        { !on ? <text fontSize="15" fill="limegreen">
            <textPath id="innerTextPath" xlinkHref="#innerCirclePath">
                {innerHermeticLoop}
            </textPath>
  </text> : null }
      </svg>
      </div>

    <div style={{position: 'fixed', bottom: '30px', right: '30px', cursor: 'pointer'}}>
        <span style={{color: 'purple', fontSize: '30px'}} onClick={() => random()}>🔮 ∴</span>
      </div>
      {/* for testing */}
      {/* <div style={{position: 'fixed', bottom: '30px', left: '30px', cursor: 'pointer'}}>
        <span style={{color: 'purple', fontSize: '30px'}} onClick={() => setCounter(counter+1)}>test</span>
      </div> */}
    </>
  )
}

function removeSingleQuotes(str: any) {
  return str.replace(/^'|'$/g, '');
}

// Ensure GSAP plugins are registered
gsap.registerPlugin(Draggable, InertiaPlugin, MotionPathPlugin);

const DialComponent = (props: any) => {
  const [dial, setDial] = useState<any>([]);
  const boxesContainerRef = useRef(null);
  const draggableRef: any = useRef();

  useEffect(() => {
    // const newData = event.data.dial;
    if(props.slots){

    console.log(props.slots)
    const arr = Array.from(props.slots).reverse()
    let lastElement = arr.pop(); // Removes the last element and returns it
    arr.unshift(lastElement);
    
      if(props.dialID == 1)setDial(arr.map((el)=>removeSingleQuotes(el)));
      else setDial(arr);
      props.setDescription(String(removeSingleQuotes(arr[0])))
    }

      // const handleMessage = (event: any) => {
    //   if (event.origin !== "http://localhost:3000/") {
    //     return; // Always verify the origin
    //   }
    //   const newData = event.data.dial;
    //   // setDial([1,2,3,4,5,6,7]);
    //   setDescription(newData.at(-1) || ''); // Update description based on the last element
    // };

    // window.addEventListener('message', handleMessage);

    // return () => {
    //   window.removeEventListener('message', handleMessage);
    // };
  }, []);

  useEffect(() => {
    if (!boxesContainerRef.current) return;
    
    // Animate boxes when dial updates
    const boxes = gsap.utils.toArray(`.box-${props.dialName}`);
    
    gsap.set(boxes, {
      //@ts-ignore
      motionPath: {
        path: `#${props.dialName}`,
        align: `#${props.dialName}`,
        alignOrigin: [0.5, 0.5],
        start: -0.25,
        end: (i: any) => i / boxes.length - 0.25,
        autoRotate: true
      }
    });

    // Set up draggable rotation with snapping
    draggableRef.current = Draggable.create(`.container-${props.dialName}`, {
      type: "rotation",
      inertia: true,
      snap: (endVal) => handleSnap(endVal, boxes.length)
    })
  }, [dial]);

  const handleSnap = (endVal: any, boxCount: any) => {
    // Calculate the number of boxes and ensure it never goes below 1 to avoid division by zero
    const numberOfBoxes = Math.max(boxCount, 1);

    // Snap the end value to the nearest division point
    const snappedValue = gsap.utils.snap(360 / numberOfBoxes, endVal);

    // Calculate the index based on the snapped value
    let index = Math.round(snappedValue / (360 / numberOfBoxes));

    // Adjust the index to be within the range of the dial array
    // This calculation correctly wraps negative indices as well
    index = ((index % dial.length) + dial.length) % dial.length;

    // Set the innerHTML of the 'description' element to the corresponding dial value
    if(props.dialID == 1) {
      props.setDescription(removeSingleQuotes(props.slots[String((index))]))
    } else {
      props.setDescription(props.slots[String((index))])
    }

    // Log the index to the console for debugging
    // console.log(index);

    // Return the snapped rotation value
    return snappedValue
  }
  useEffect(() => {

  }, [props.updateZIndex])

  return (
    <div className="wrapper" style={{zIndex: props.updateZIndex ? '-1':'1'}}>
    <div className={`container container-${props.dialName}`}>
      <svg viewBox="0 0 400 400">
        <path stroke-width="2" stroke="transparent" id={props.dialName} fill="none" d="M396,200 C396,308.24781 308.24781,396 200,396 91.75219,396 4,308.24781 4,200 4,91.75219 91.75219,4 200,4 308.24781,4 396,91.75219 396,200 z"></path>
      </svg>
      <div ref={boxesContainerRef} className={`boxes-container boxes-container${props.dialName}`}>
        {dial.map((element: any, index: any) => (
          <div key={index} className={`box box-${props.dialName}`}>{element}</div>
        ))}
      </div>
    </div>
    <div id="description">{props.description}</div>
  </div>
  );
};

//@ts-ignore
// import Draggable from 'https://cdn.skypack.dev/gsap@3.7.0/Draggable'

//@ts-ignore
// import InertiaPlugin from 'https://assets.codepen.io/16327/InertiaPlugin.min.js'
//@ts-ignore
// import MotionPathPlugin from 'https://unpkg.com/gsap@3/dist/MotionPathPlugin.min.js'
// InertiaPlugin, MotionPathPlugin

function loadScript(src: any, onLoad: any) {
  const script = document.createElement('script');
  script.src = src;
  script.onload = onLoad;
  script.async = true;
  document.head.appendChild(script);
}

const values = ['☿','♂','☿','🜂','♄','🜃','♃','♀','♅','♆','♄','☽','♇','🜄','☉','🜁']

    // setTimeout(() => {
    //   gameMake2()
    // gameMove2()
    // }, 1500)
    
function CirclesGrid({ items, highlightedCircles, setHighlightedCircles, account, setAddDial, addDial, setDial1, setDial2, dateChange}: any) {

  // Toggle the highlight state of the circle
  const toggleHighlight = (index: any) => {
    setHighlightedCircles((prev: any) => {
      const newHighlighted = new Set(prev);
      if (newHighlighted.has(index)) {
        if(Array.from(highlightedCircles)[0] == index){
          console.log('setting dial 1 to 0')
          setDial1([])
        } else if(Array.from(highlightedCircles)[1] == index) {
          console.log('setting dial 2 to 0')
          setDial2([])
        }

        newHighlighted.delete(index); // If already highlighted, unhighlight it
      } else {
        if(highlightedCircles.size < 2 ) newHighlighted.add(index); // If not highlighted, highlight it
      }
      return newHighlighted;
    });
  };

  // Style for the circles
  const circleStyle = (isHighlighted: any) => ({
    width: '100px', // Circle size
    height: '100px', // Circle size
    borderRadius: '50%', // Makes the div circular
    border: '2px solid #ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px', // Margin around circles
    cursor: 'pointer',
    backgroundColor: isHighlighted ? '#B1FFED' : 'transparent', // Highlight color
  });

  // Style for the grid container
  const gridContainerStyle: any = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap', // Allows for wrapping items
    gap: '10px', // Gap between circles
  };

  const loadNewDial = async () => {
    const wallet = sequence.getWallet()
    const signer = wallet.getSigner(11155111)

    const userInput = prompt("Please enter your dial:");

    // Check if the user clicked "OK" and entered some text
    if (userInput !== null) {
        const dialInterface = new ethers.utils.Interface(
          [
            'function load(string[] memory slots) public onlyOwner'
          ],
        );
        console.log(userInput)
        const jsonStringArray = userInput.replace(/'/g, '"');

        // Parse the JSON string into an actual array
        const actualArray = JSON.parse(jsonStringArray);

        const data = dialInterface.encodeFunctionData('load', [actualArray])
        const tx = {
          to: '0xb8f9bf7de55ef4d0cf95d059c944808bda098032',
          data
        }
        console.log(tx)
        const res = await signer.sendTransaction(tx)
        setAddDial(addDial + 1)
    } else {
        console.log("User did not provide their name.");  // Handle the situation where no input was given
    }
  }
  return (
    <div>
      <div style={gridContainerStyle}>
        {items.map((item: any, index: any) => (
          <div
            key={index}
            style={circleStyle(highlightedCircles.has(index))}
            onClick={() => {toggleHighlight(index);}}
          >
            {item.content}
          </div>
        ))}
        {
          account == '0xBAbebe9FE973a5735D486BF6D31e9a027248024e' && <div
            style={circleStyle(false)}
            onClick={() => {dateChange(new Date());loadNewDial()}}
          >
            {'+'}
          </div>
        }
        {/* Additional circle with a plus sign for adding new items */}
      </div>
    </div>
  );
}
    
function GridComponent({ items, setActiveDialSection, setActivePathID }: any) {

  const addNewDial = () => {
    console.log('here')
    setActiveDialSection(1)
  }

  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)', // three columns with equal width
      gridGap: '10px', // space between boxes
      maxWidth: '600px', // maximum width of the grid
      margin: '0 auto', // center the grid horizontally
    }}>
      {items.length === 0 ? (
        // Render a single plus box if there are no items
        <div onClick={() => addNewDial()} style={{width: '200px', height: '200px', border: '2px dashed black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
          +
        </div>
      ) : (
        // Otherwise, map through the items and render them, including the plus box as the last item
        <>
          {items.map((item: any, index: any) => (
            <div onClick={() => {setActivePathID(item.pathID);setActiveDialSection(2)}} key={index} style={{width: '200px', cursor: 'pointer', height: '200px', alignItems: 'center', justifyContent:'center',paddingTop: '90px', textAlign: 'center', border: '1px solid black'}}>
              {item.content} {/* Replace with actual content */}
            </div>
          ))}
          <div onClick={() => addNewDial()} style={{width: '200px', height: '200px', border: '2px dashed black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
            +
          </div>
        </>
      )}
    </div>
  );
}

let left = 40
let right = 68
let chars = 4

function HighlightSpans(props: any) {

  // Styles for the container
  const containerStyle = {

      position: 'fixed',
      top: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',      
      height: '10vh', // Take the full height of the viewport
  };

  // Styles for each span
  const spanStyle = (isActive: any) => ({
    padding: '14px',
    paddingBottom: '5px', // Adjust padding to create a gap
    cursor: 'pointer',
    width: '70px',
    height: '37.4px',
    display: "inline-block",
    borderBottom: isActive ? '1px solid #B1FFED' : 'transparent', // Underline effect with gap
    marginBottom: isActive ? '-1px' : '0' 
  });

  // Function to handle click on span
  const handleClick = (index: any) => {
      props.setActiveSpan(index);
  };

  return (
      <div style={{
        position: 'fixed',
        top: '30px',
        transform: 'translate(-50%, -50%)',
        left: '51%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',      
        height: '10vh', // Take the full height of the viewport
    }}>
          {/* <span style={spanStyle(props.activeSpan === 0)} onClick={() => handleClick(0)}>pearl</span> */}
          <span style={spanStyle(props.activeSpan === 1)} onClick={() => handleClick(1)}>dial</span>
          <span style={{
    padding: '14px',
    paddingBottom: '5px', // Adjust padding to create a gap
    cursor: 'pointer',
    width: '70px',
    height: '37.4px',
    display: (props.activeSpan === 1 || props.activeSpan === 2) && props.activeDialSection == 2 ? "inline-block" : 'none',
    borderBottom: props.activeSpan === 2 ? '1px solid #B1FFED' : 'transparent', // Underline effect with gap
    marginBottom: props.activeSpan === 2 ? '-1px' : '0' 
  }} onClick={() => handleClick(2)}>acc</span>
      </div>
  );
}


function Deuce() {
  const [highlightedCircles, setHighlightedCircles] = useState<any>(new Set());
  const [account, setAccount] = useState<any>('');
  const [addDial, setAddDial] = useState<any>(0)
  const [activeSpan, setActiveSpan] = useState<any>(1);

  const [isLoggedIn, setIsLoggedIn] = useState<any>(false)
  const [counter, _] = useState<any>(0)
  const [whl, setWhl] = useState<any>('')
  const [talisLoop, setTalis] = useState<any>('')
  const [logo, setLogo] = useState<any>('')

  const [tooltipRight, setTooltipRight] = useState<any>('');
  const [tooltipLeft, setTooltipLeft] = useState<any>('');

  const [sliderValue, setSliderValue] = useState<any>(50); // Default slider value
  const [entries, setEntries] = useState<any>([]);
  const [activeDialSection, setActiveDialSection] = useState<any>(0)
  // const [leftValue, setLeftValue] = useState(40); // Default slider value
  // const [rightValue, setRightValue] = useState(68); // Default slider value

  // Provider setup (using a public provider here for the example)
const provider = new ethers.providers.JsonRpcProvider('https://nodes.sequence.app/sepolia/AQAAAAAAAExvUQz5o6U0PkPT1uU_gZUNdEA');

// Contract instance
const contract = new ethers.Contract('0xb8f9bf7de55ef4d0cf95d059c944808bda098032', abi.abi, provider);

async function getSlots(dialID: any) {
  try {
      const dial = await contract.getDial(dialID);
      return dial.slots
  } catch (error) {
      console.error('Error fetching dial:', error);
      return null; // This handles when getDial doesn't return anything valid
  }
}

async function getAllSlotElement(dialID: any) {
  try {
      const dial = await contract.getDial(dialID);
      return dial.slots.length > 0 ? dial.slots : null;
  } catch (error) {
      console.error('Error fetching dial:', error);
      return null; // This handles when getDial doesn't return anything valid
  }
}

async function getFirstSlotElement(dialID: any) {
    try {
        const dial = await contract.getDial(dialID);
        return dial.slots.length > 0 ? dial.slots[0] : null;
    } catch (error) {
        console.error('Error fetching dial:', error);
        return null; // This handles when getDial doesn't return anything valid
    }
}

async function getAllSlots() {
  let dialID = 0;
  let hasMore = true;
  let allSlots = [];

  while (hasMore) {
      const firstSlotElement = await getAllSlotElement(dialID);

      if (firstSlotElement !== null) {
        allSlots.push(firstSlotElement);
          dialID++; // Increment the dialID for the next iteration
      } else {
          hasMore = false; // Stop the loop if there is no valid response
      }
  }

  return allSlots;
}

async function getAllFirstSlots() {
    let dialID = 0;
    let hasMore = true;
    let firstSlots = [];

    while (hasMore) {
        const firstSlotElement = await getFirstSlotElement(dialID);

        if (firstSlotElement !== null) {
            firstSlots.push(firstSlotElement);
            dialID++; // Increment the dialID for the next iteration
        } else {
            hasMore = false; // Stop the loop if there is no valid response
        }
    }

    return firstSlots;
}

function convertDayOfYearToDate(dayOfYear: any, year: any) {
  const date = new Date(year, 0); // Start at January 1 of the given year
  date.setDate(dayOfYear); // Set the date to dayOfYear days after January 1
  return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
}

  async function getVerePathsByOwner(ownerAddress: any) {
    try {
      const pathIDs = await contract.getVerePathsByOwner(ownerAddress);
      const paths = []
      for(let i = 0; i< pathIDs.length; i++){
        const path = await contract.paths(pathIDs[i]);
        paths.push({pathID: pathIDs[i], content: "path id: " + pathIDs[i].toString()+", \n\n"+convertDayOfYearToDate(path[1]-11, 2024) })
      }



      return paths; // This will be an array of uints
    } catch (error) {
      console.error('Error fetching paths:', error);
      return []; // Return an empty array in case of an error
    }
  }

  useEffect(() => {
    if(activeDialSection == 0 && isLoggedIn){
      getVerePathsByOwner(account).then(firstSlots => {
        console.log('First slots:', firstSlots);
        
        // const contenized = firstSlots.map(el => {return {content: removeSingleQuotes(el)}})
        setPathItems(firstSlots)
      }).catch(error => {
        console.error('An error occurred:', error);
      });
    }
    if(activeDialSection == 1){
      // Calling the function to retrieve all first slots
      getAllFirstSlots().then(firstSlots => {
        console.log('First slots:', firstSlots);
        function removeSingleQuotes(str: any) {
          return str.replace(/^'|'$/g, '');
        }
        const contenized = firstSlots.map(el => {return {content: removeSingleQuotes(el)}})
        setCircleItems(contenized)
      }).catch(error => {
        console.error('An error occurred:', error);
      });
    }
  }, [isLoggedIn, activeSpan, activeDialSection, account, addDial])

  useEffect(( )=> {}, [tooltipLeft, activeDialSection])

  useEffect(() => {

    if(isLoggedIn && activeSpan == 2 && activeDialSection == 2){


    //The code below creates a simulation of waves
    var parts: any = [];
    var parts2: any = [];
    var waves: any = []
    var waves2: any = []
    var can: any = document.getElementById("cs");
    var cad: any = document.getElementById("cm");
    var ctx = can.getContext("2d")
    can.width = window.innerWidth*.35;
    can.height = window.innerHeight*.6;
    
    const randFrom = (min: any,max: any) => {
       //This function selects a random   decimal number from the minimum to the maximum value 
    return Math.random() * (max - min) + min;
    }
    const randBet = (c1: any,c2: any) => {
       //This function picks a random item between choice 1 and choice 2
    var nArr = [c1,c2];
    return nArr[Math.floor(Math.random()*2)];
    }
    var Wave = function (period: any,amp: any,waveL: any,dir: any): any  {
       //This is an object function for creating a Wave with attributes of: period,amplitude,wavelength and direction, all in terms of pixels
        // @ts-ignore
       this.phase = 0
    if(dir == "left") {
    var dirVal = 1
    } else if(dir == "right") {
    var dirVal = -1
    }
        // @ts-ignore
    this.applyTo = function(points: any) {
        //This sub-function applies the wave properties to a list of points
    for(var i = 0; i < points.length; i++) {
    var initPhase = 2*Math.PI*points[i].x/waveL
    points[i].y += amp*Math.sin(this.phase + (initPhase*dirVal))
    var yVal = amp*Math.sin(this.phase + (initPhase*dirVal))
    var angVel = 2*Math.PI/period
    points[i].acc += -(angVel**2)*yVal
    }
    this.phase += 2*Math.PI/period
     }
    }
    var particle = function (x: any, y: any): any {
        //This is an object function that creates a particle with an x-coordinate
    //@ts-ignore
    this.x = x+11
    //@ts-ignore

    this.y = 0
    //@ts-ignore

    this.acc = 0
    //@ts-ignore

    this.upd = function() {
        //This sub-function renders and updates the state of the particle
    if(y) {
        ctx.strokeStyle = "hsl(280,100%," + (Math.abs(this.acc*60)+30) + "%)"
    } else {
      ctx.strokeStyle = "grey"
    }
    
    ctx.beginPath();
    ctx.arc(this.x,can.height/2+this.y,10,0,2*Math.PI);
    ctx.stroke();
    this.y = 0
    this.acc = 0
    }}
    const gameMake = () => {
       //This function determines the initial setup of the program
    var num = 20
    for(var i = 0; i < num; i++) {
      //@ts-ignore
    parts.push(new particle(i/(num-1)*can.width, false))
    }
    var percentage = 200
  //   const loadedEntries = JSON.parse(localStorage.getItem('sliderEntries') || '[]');

    function parsePercentage(inputString: any) {
      // Use a regular expression to extract the number from the string.
      const result = inputString.match(/\d+/);
  
      // Check if the match was successful and parse the result as an integer.
      if (result) {
          const number = parseInt(result[0], 10);
  
          // Check if the number is within the 0 to 100 range.
          if (number >= 0 && number <= 100) {
              return number;
          } else {
              throw new Error("The number is out of the acceptable range (0-100).");
          }
      } else {
          throw new Error("No number found in the input string.");
      }
  }

    var values = entries.slice(-7).map((el: any) => parsePercentage(el.left));
    console.log(values)
    // var values = [50,50,50,50,50,50,100]
    var waveNum = values.length
    
    for(var i = 0; i < waveNum; i++) {
      //@ts-ignore
    waves.push(new Wave(percentage,can.height/(3*waveNum),values[i],randBet("left","right")))
    }
    }
    
    const gameMove = () => {
        //This function updates and animates the program
    requestAnimationFrame(gameMove)
    ctx.clearRect(0,0,can.width,can.height)
    for(var i = 0; i < waves.length; i++) {
    waves[i].applyTo(parts)
    }
    for(var i = 0; i < parts.length; i++) {
    parts[i].upd();
    }}
    
    gameMake()
    gameMove()

    const onStorageEvent = (storageEvent: any) => {
      console.log('storing')
      gameMake()
      gameMove()
    }
  
  }
  }, [entries, isLoggedIn, activeSpan, activeDialSection])


  useEffect(() => {
    // if (!CSS.supports('animation-timeline: scroll()')) {

    setTimeout(async () => {
      if(isLoggedIn && activeSpan == 1 && activeDialSection == 2){

        const CONTROL = document.querySelector('.control') as HTMLElement
        const TRACK = CONTROL.querySelector('.control__track')  as HTMLElement
        const LABEL = CONTROL.querySelector('.tooltip')  as HTMLElement
        const INPUT = document.querySelector('input')  as any
        const update = () => {
          console.log('left',left)
          console.log('right',right)
          CONTROL.style.setProperty('--value', INPUT.value)
          TRACK.style.setProperty('--shift', String(INPUT.value > left && INPUT.value < 100-right ? 1 : 0))
          LABEL.style.setProperty('--shift', String(INPUT.value > left && INPUT.value < 100-right ? 1 : 0))
        }
        INPUT.addEventListener('input', update)
        INPUT.addEventListener('pointerdown', update)
        update()

      
      document.getElementById('track')!.addEventListener('input', function(event: any) {
          updateArcs(event.target.value);
        });
      
        const updateArcs = (value: any) => {
          const leftPath = document.getElementById('leftPath') as any
          const rightPath = document.getElementById('rightPath') as any
          
          // Convert the range value to a percentage of the semi-circle
          const leftPercentage = value / 100;
          const rightPercentage = 1 - leftPercentage;
          
          // Calculate the end X position for the left and right arcs
          const leftEndX = 50 + (100 * leftPercentage);
          const rightEndX = 150 - (100 * rightPercentage);
          
          // Update the paths for the left and right semi-circles
          leftPath.setAttribute('d', `M 50,50 A 50,50 0 0 1 ${leftEndX},0`);
          rightPath.setAttribute('d', `M 150,50 A 50,50 0 0 0 ${rightEndX},0`);
          
          // Apply rotation
          leftPath.setAttribute('transform', 'rotate(90 100 50)');
          rightPath.setAttribute('transform', 'rotate(270 100 50)');
        }

        const tooltipElement = document.getElementById('tool-tip') as any
        console.log(activePathID.toString())
        const dialIDs = JSON.parse(localStorage.getItem(activePathID.toString())!)
        const values = (await getSlots(dialIDs[0])).map((el: any) => removeSingleQuotes(el))
        console.log(values)
        values.forEach((value: any, index: any) => {
          if(getDayOfYear()%values.length == index){
            setWhl(value)
            // setRightValue(value.length * 30)
            right = value.length * chars*4
            tooltipElement.style.setProperty('--right', `${value}`);
            
            setTooltipRight(`${value}`)
          }
        });

        if(dialIDs.length > 1){

        // const entries = Object.entries(library);
        const entries = await getSlots(dialIDs[1])
        console.log(entries)
        let talis = '';
        let max = 0;
          // document.getElementById('blueTextPath')!.setAttribute('startOffset', blueOffset + '%');
        const offset = getDayOfYear()
      
        // Find the maximum length
        entries.forEach(([value]: any, index: any) => {
          if (value.length > max) {
            max = value.length;
          }
          if(offset%entries.length == index){
            setLogo(value)
            // setLeftValue(value.length * 30)

            left = value.length * chars
            tooltipElement.style.setProperty('--left', `${value}`);

            // tooltipElement.style.setProperty('--tooltip-before-content', `"${sliderValue}"`);

            setTooltipLeft(`${value}`)
          }
        });
      }
      getEntries()
      }
    }, 0)

  }, [logo, whl, isLoggedIn, activeSpan, activeDialSection, tooltipLeft, ])

  useEffect(() => {
    if(isLoggedIn && activeSpan == 1 && activeDialSection == 2){
    const tooltipElement = document.getElementById('tool-tip') as any
    tooltipElement.style.setProperty('--tooltip-before-content', `"${tooltipLeft} ${sliderValue}%"`);
    tooltipElement.style.setProperty('--tooltip-after-content', `"${tooltipRight} ${100-sliderValue}%"`);
  }

  }, [sliderValue, logo, isLoggedIn, activeSpan, activeDialSection, tooltipLeft])

  const handleSliderInput = (event: any) => {
    setSliderValue(event.target.value);
  };

  useEffect(() => {

  }, []);

  const handleSliderChange = (event: any) => {
    setSliderValue(event.target.value);
  };

  const getEntries = async () => {
    const updatedEntries = []
    const path = await contract.getVere(activePathID.toString())

    const dialIDs = JSON.parse(localStorage.getItem(activePathID.toString())!)
    console.log(dialIDs)
    const entries = await getSlots(dialIDs[0])
    const values = await getSlots(dialIDs[1])
    console.log(entries)
    console.log(values)
    for(let i = 0; i < path.length; i++){
      console.log(path[i].dayOfYear.toString())

      const newEntry = {
        // date: new Date().toISOString(),
        left: `${path[i].observation[0].toString()} %`, // Assuming you're using sliderValue for left
        right: `${path[i].observation[1].toString()}%`, // Assuming the right value is calculated like this
        whl: removeSingleQuotes(entries[Number(path[i].dayOfYear.toString()-11) % entries.length]), // Replace with actual logic
        logo: values[Number(path[i].dayOfYear.toString()-11) % values.length], // Replace with actual logic
      };
      console.log(newEntry)
      updatedEntries.push(newEntry)
    } 


    // localStorage.setItem('sliderEntries', JSON.stringify(updatedEntries));
    setEntries(updatedEntries); // Update state to re-render the table
  }

  const handleButtonClick = async () => {

    const wallet = sequence.getWallet()
    const signer = wallet.getSigner(11155111)

    const dialInterface = new ethers.utils.Interface(
      [
        'function vere(uint pathID, uint[] memory dialIDs, uint[] memory observation) public'
      ],
    );
    console.log(activePathID.toString(),[JSON.parse(localStorage.getItem(activePathID.toString())!)],[sliderValue, 100 - sliderValue])
    const data = dialInterface.encodeFunctionData('vere', [activePathID.toString(),JSON.parse(localStorage.getItem(activePathID.toString())!).reverse(),[sliderValue, 100 - sliderValue]])

    const tx = {
      to: '0xb8f9bf7de55ef4d0cf95d059c944808bda098032',
      data
    }
    console.log(tx)
    const res = await signer.sendTransaction(tx)


    // Load existing entries, add the new one, and save back to localStorage
    getEntries()
  };

  sequence.initWallet('AQAAAAAAAExvUQz5o6U0PkPT1uU_gZUNdEA', {defaultNetwork: 'sepolia'})

  const login = async () => {
    const wallet = sequence.getWallet()
    const details = await wallet.connect({app: 'dial'})
    if(details.connected){
      setIsLoggedIn(true)
      setAccount(details.session!.accountAddress)
    }
  }

  useEffect(() => {

  }, [activeSpan, entries])

  const [circleItems, setCircleItems] = useState<any>([])
  const [pathItems, setPathItems] = useState<any>([])
  const [activePathID,setActivePathID] = useState<any>(null)

  useEffect(() => {

  }, [highlightedCircles, pathItems,activePathID])

  const [burs, setBurs] = useState<any>(null)

  useEffect(()=> {
    let burTemp: any = []

    entries.map(() => burTemp.push(<br/>))

    setBurs(burTemp)
  }, [entries])

  const createPath = async () => {
    const wallet = sequence.getWallet()
    const signer = wallet.getSigner(11155111)

    const dialInterface = new ethers.utils.Interface(
      [
        'function createPath() public'
      ],
    );

    const data = dialInterface.encodeFunctionData('createPath', [])

    const tx = {
      to: '0xb8f9bf7de55ef4d0cf95d059c944808bda098032',
      data
    }
    const res = await signer.sendTransaction(tx)

    const pathIDs = await contract.getVerePathsByOwner(account);

    localStorage.setItem(pathIDs.map((el: any) => el.toString())[pathIDs.length -1 ], JSON.stringify(Array.from(highlightedCircles).reverse()))

  }
  const [dial1, setDial1] = useState([])
  const [dial2, setDial2] = useState([])
  const iframe = 'https://usb.ngrok.app/imported_apps/dial_frame'
  useEffect(() => {
  //   if(highlightedCircles.size == 0){
  //     const frame = document.getElementById('dial-1') as any

  //     frame&&frame.contentWindow.postMessage({dial: []}, iframe);
  //   }

  //   if(highlightedCircles.size == 1){
  //     const frame = document.getElementById('dial-2') as any

  //     frame&&frame.contentWindow.postMessage({dial: []}, iframe);
  //   }

    if(activeSpan == 1 && activeDialSection == 1 && highlightedCircles.size > 0){
      
      setTimeout(async () => {
        const dialIDs = Array.from(highlightedCircles)
  //       console.log(dialIDs)
  //       const frames = []

  //       for(let i = 0; i < dialIDs.length; i++){
  //         // console.log(JSON.stringify(Object.values(library).slice(0,14)))
  //         // iframe.contentWindow.postMessage(JSON.stringify(slots[1]), 'http://localhost:8004/dist/');
  //         frames.push(document.getElementById('dial-' + (i+1)) as any)
  //         // console.log(JSON.stringify(Object.values(library).slice(0,14)))
  //         // iframe.contentWindow.postMessage(JSON.stringify(Object.values(library).slice(0,15)), 'http://localhost:8003/dist/');
  //       }

  //       if(highlightedCircles.size==1){
  //         frames[1]&&frames[1].contentWindow.postMessage({dial: []}, iframe);
  //       }

        for(let i = 0; i < dialIDs.length; i++){
        const slots = await contract.getDial(dialIDs[i]);
        if(i == 0){
          console.log(slots.slots)
          setDial1(slots.slots)
        } else {
          setDial2(slots.slots)
        }
  //       const slotArray = slots.slots.slice()
  //       slotArray.reverse()
  //       let lastElement = slotArray.pop(); // Removes the last element and returns it
  //       slotArray.unshift(lastElement);
  //       if(dialIDs[i] == 1){
  //         frames[i].contentWindow.postMessage({dial: slotArray.map((el) => removeSingleQuotes(el))}, iframe);

  //       } else {
  //         frames[i].contentWindow.postMessage({dial: slotArray}, iframe);

  //       }

      }

  //       // const slots = await contract.getDial(highlightedCircles[0]);
  //       // getSlots(dialID).then((slots: any) => {
  //         // console.log(slots)
  //         // var iframe = document.getElementById('dial-1') as any
  //         // console.log(JSON.stringify(Object.values(library).slice(0,14)))
  //         // iframe.contentWindow.postMessage(JSON.stringify(slots[1]), 'http://localhost:8004/dist/');
  //         // var iframe = document.getElementById('dial-2') as any
  //         // console.log(JSON.stringify(Object.values(library).slice(0,14)))
  //         // iframe.contentWindow.postMessage(JSON.stringify(Object.values(library).slice(0,15)), 'http://localhost:8003/dist/');
  //       // })
     
    }, 0)
    
    }
  }, [activeSpan, activeDialSection, highlightedCircles])

  useEffect(() => {

  }, [dial1, dial2])
  const [value, onChange] = useState<any>(new Date());

  function dayOfYear(dateString: any) {
    // Parse the date string
    const date: any = new Date(dateString);

    // Get the start of the year
    const startOfYear: any = new Date(date.getFullYear(), 0, 0);

    // Calculate the difference in milliseconds
    const diff = date - startOfYear;

    // Convert milliseconds to days
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);

    return day;
}

const [description, setDescription] = useState('')
const [description2, setDescription2] = useState('')

  useEffect(() => {
    const boxes = gsap.utils.toArray(`.box-${'path-1'}`);
    const boxes2 = gsap.utils.toArray(`.box-${'path-2'}`);

    const snapToIndex = (index: any) => {
      const anglePerItem = 360 / boxes.length;
      const targetRotation = index * anglePerItem;
    
      // Use gsap.to for the animation
      gsap.to(`.container-${'path-1'}`, {
        duration: 1,
        rotation: targetRotation,
        ease: "power1.inOut",
        onComplete: () => {
          // draggableRef.current[0].endRotation = targetRotation; // Update Draggable's end rotation
        }
      });
      const boxes2 = gsap.utils.toArray(`.box-${'path-2'}`);
      const anglePerItem2 = 360 / boxes2.length;
      const targetRotation2 = index * anglePerItem2;
    
      // Use gsap.to for the animation
      gsap.to(`.container-${'path-2'}`, {
        duration: 1,
        rotation: targetRotation2,
        ease: "power1.inOut",
        onComplete: () => {
          // draggableRef.current[0].endRotation = targetRotation; // Update Draggable's end rotation
        }
      });
    };
    console.log(value)
    console.log(dayOfYear(value))
    //getDayOfYear()
    if(highlightedCircles.size > 0){
      snapToIndex(((11+dayOfYear(value)))%boxes.length)
      if(Array.from(highlightedCircles)[0] == 0){
        setDescription(dial1[(11+dayOfYear(value))%boxes.length])
      } else if(Array.from(highlightedCircles)[0] == 1){
        setDescription(removeSingleQuotes(dial1[(11+dayOfYear(value))%boxes.length]))

      } else {
        setDescription(dial1[(11+dayOfYear(value))%boxes.length])
      }

      if(Array.from(highlightedCircles)[1] == 1){
        setDescription2(removeSingleQuotes(dial2[(3+dayOfYear(value))%boxes2.length]))
      } else if(Array.from(highlightedCircles)[1] == 1){
        setDescription2(removeSingleQuotes(dial2[(3+dayOfYear(value))%boxes2.length]))
      }else if(Array.from(highlightedCircles)[1] == 2){
        setDescription2(removeSingleQuotes(dial2[(3+dayOfYear(value))%boxes2.length]))
      }
    }
  }, [value])

  const [updateZIndex, setUpdateZIndex] = useState(false)
  useEffect(() => {

  }, [description, description2, updateZIndex, value])
  return (
    <>

      {
        isLoggedIn ? 
        <><HighlightSpans setActiveSpan={setActiveSpan} activeSpan={activeSpan} activeDialSection={activeDialSection}/></>
        :
          null
      }
      {
        highlightedCircles.size > 0 ?<div style={{position: 'fixed', top: '125px', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <div className='iframe-container' style={{margin: 'auto', textAlign: 'center'}}>
            <DateTimePicker onClick={() => setUpdateZIndex(true)} onChange={(evt) => {onChange(evt);setTimeout(() => setUpdateZIndex(false), 100)}} value={value} />
          </div>
          <br/>
          <br/>
        </div> : null
      }
      {!isLoggedIn ? 
      <>
      <div style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh' // Ensures the container takes up the full height of the viewport
  }}>
    <img style={{width: '250px', borderRadius: '150px'}} src={'https://usb.ngrok.app/%E2%99%A6/metadata/dial/download_dial_grey.png'} />
    <br/>
    <br/>
    <button onClick={() => login()}>login</button>
  </div>
      </>
      :
        <>
        {
          activeSpan == 0 ? <>
              <svg style={{marginLeft: '12px'}} width="150" height="150" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="60" cy="60" r="60" fill="white" />
              </svg>
          </> 
          : activeSpan == 1 ? <>
            {
              activeDialSection == 0 ? 
                <>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                    <GridComponent setActivePathID={setActivePathID} setActiveDialSection={setActiveDialSection} items={pathItems} />
                </>
              :

              activeDialSection == 2 ?
                <>
                  <div className='app-container'>
                    <svg id="leftSvg" style={{marginRight: '-40px'}}  width="200" height="200" xmlns="http://www.w3.org/2000/svg">
                      <path id="leftPath" fill="none" stroke="blue" stroke-width="2"/>
                      <text x="100" y="50" font-family="Arial" font-size="14" fill="black" text-anchor="middle" dominant-baseline="middle">{logo}</text>
                    </svg>
                    <svg id="rightSvg" width="200" height="200" xmlns="http://www.w3.org/2000/svg" style={{marginLeft: '-40px'}}>
                      <path id="rightPath" fill="none" stroke="red" stroke-width="2"/>
                      <text x="100" y="50" font-family="Arial" font-size="24" fill="black" text-anchor="middle" dominant-baseline="middle">{whl}</text>
                    </svg>

                  <div className="control">
                        <input onInput={handleSliderInput} id="track" type="range" min="0" max="100" value="50" />
                        <div id="tool-tip" aria-hidden="true" className="tooltip"></div>
                        <label htmlFor="track" className="sr-only">Coffee to Milk Ratio</label>
                        <div className="control__track">
                          <div className="control__indicator"></div>
                        </div>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <div className='write-button'>
                      <button onClick={handleButtonClick}>Write</button>
                    </div>

                    <br/><br/><br/>
                    <div className="table-container">

                    <table >
                      <thead>
                        <tr>
                          {/* <th>Date</th> */}
                          <th>Left</th>
                          <th>Right</th>
                          <th>Talis</th>
                          <th>WHL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entries.map((entry: any, index: any) => (
                          <tr key={index}>
                            {/* <td>{entry.date}</td> */}
                            <td>{entry.left}</td>
                            <td>{entry.right}</td>
                            <td>{entry.logo}</td>
                            <td>{entry.whl}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {burs}
                    </div>
                    <button onClick={() => {setEntries([]);setActiveDialSection(0)}} style={{position: 'fixed', bottom: '50px', left: '50px'}}>back</button>

                </div>
                </>
              : 
                <>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="iframe-container">
                {dial1.length>0&&highlightedCircles.size >= 1 && <DialComponent updateZIndex={updateZIndex} description={description} setDescription={setDescription} dialID={Array.from(highlightedCircles)[0]} slots={dial1} dialName={"path-1"}/>}
                {dial2.length>0&&highlightedCircles.size == 2 && <DialComponent updateZIndex={updateZIndex} description={description2}   setDescription={setDescription2} dialID={Array.from(highlightedCircles)[1]} slots={dial2}dialName={"path-2"}/>}
                {/* <iframe id="dial-1" src={iframe} width={360} height={360} style={{borderRadius: '250px', marginLeft:'10px',  marginTop:'-50px'}}/> */}
                {/* <iframe id="dial-2" src={iframe} width={360} height={360} style={{borderRadius: '250px', marginLeft:'10px',  marginTop:'-50px'}}/> */}
                </div>
                <div style={{marginLeft: '15px', marginTop: '40px'}}>
                <p style={{textAlign: 'center'}}>choose 2 dials</p>
                <button onClick={() => {setHighlightedCircles(new Set());setActiveDialSection(0)}} style={{position: 'fixed', bottom: '50px', left: '50px'}}>back</button>
                <CirclesGrid setDial1={setDial1} setDial2={setDial2} setAddDial={setAddDial} addDial={addDial} account={account} highlightedCircles={highlightedCircles} setHighlightedCircles={setHighlightedCircles} items={circleItems} dateChange={onChange}/>
                <button onClick={() => createPath()} disabled={highlightedCircles.size < 2} style={{position: 'fixed', bottom: '50px', right: '50px'}}>create</button>
                </div>

                </>
            }

            <br/>
            <br/>
            <br/>
            <br/>
              </>
              : <>
                <canvas id="cs"></canvas>
              </>
            }
        </>
      }
    </>

  );
}

function Uno() {
  const strings = [
    "nor","bot","wic","soc","wat","dol","mag","pic","dav","bid","bal","tim","tas","mal","lig","siv",
    "tag","pad","sal","div","dac","tan","sid","fab","tar","mon","ran","nis","wol","mis","pal","las",
    "dis","map","rab","tob","rol","lat","lon","nod","nav","fig","nom","nib","pag","sop","ral","bil",
    "had","doc","rid","moc","pac","rav","rip","fal","tod","til","tin","hap","mic","fan","pat","tac",
    "lab","mog","sim","son","pin","lom","ric","tap","fir","has","bos","bat","poc","hac","tid","hav",
    "sap","lin","dib","hos","dab","bit","bar","rac","par","lod","dos","bor","toc","hil","mac","tom",
    "dig","fil","fas","mit","hob","har","mig","hin","rad","mas","hal","rag","lag","fad","top","mop",
    "hab","nil","nos","mil","fop","fam","dat","nol","din","hat","nac","ris","fot","rib","hoc","nim",
    "lar","fit","wal","rap","sar","nal","mos","lan","don","dan","lad","dov","riv","bac","pol","lap",
    "tal","pit","nam","bon","ros","ton","fod","pon","sov","noc","sor","lav","mat","mip","fip"
  ];

  const [key, setKey] = useState(0);
  
  const result: any = [];
  for (let i = 0; i < strings.length; i += 16) {
    if (i + 16 <= strings.length) {
      result.push(strings.slice(i, i + 16));
    } else {
      result.push(strings.slice(i));
    }
  }
  
  const [updateZIndex, setUpdateZIndex] = useState(false)
  const [index, setIndex] = useState("1")

  const [description, setDescription] = useState('')
  const [dial, setDial] = useState<any>(result[Number(index)-1 ])
  const indexes = ["1","2","3","4","5","6","7","8","9","10"]
  
  useEffect(() => {
    if(index){
    const dayOfYear = getDayOfYear()
    const currentIndex = Number(index) - 1;
    const offset = dayOfYear % result[currentIndex].length;
    const arrayLength = result[currentIndex].length;
    const newDial = [
      ...result[currentIndex].slice(offset, arrayLength),
      ...result[currentIndex].slice(0, offset)
    ];
    setDial(newDial);
    setKey(prevKey => prevKey + 1);
  }

  }, [index]);

  useEffect(() => {
    if(localStorage.getItem('path')){
      setIndex(localStorage.getItem('path')!)
    }
  },[])

  return (
    <>
      <Dropdown value={index} options={indexes} onChange={(evt: any) => {
        setIndex(indexes[Number(evt.value)-1])
        localStorage.setItem('path', indexes[Number(evt.value)-1])
      }} placeholder="Select an option" />
      <DialComponent key={key} updateZIndex={updateZIndex} description={description} setDescription={setDescription} dialID={1} slots={dial} dialName={"path-1"}/>
    </>
  )
}

const styles = {
  container: {
    display: 'grid',
    placeItems: 'center',
    height: '100vh',
  },
};

function App() {
  
  const [view, setView] = useState(0)

  return (
    <>
      {
        view == 0 ? 
        <>
          <br/>
          <br/>
          <p style={{fontFamily: 'Gothic', fontSize: '30px', textAlign: 'center'}}>h  &nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;  r&nbsp;&nbsp;&nbsp;  o</p>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <div className="App" style={{marginLeft: '25px'}}>
            <span onClick={() => setView(1)} className="hover-text span" data-hover="256: many days to chaos">|</span>
            <span onClick={() => setView(2)} className="hover-text span" style={{fontSize: '50px'}} data-hover="uno: a mono dial">◌</span>
            <span onClick={() => setView(3)} className="hover-text span" style={{fontSize: '50px'}} data-hover="deuce: a dual cycle system with weights">∞</span>
          </div>
        </>
        :
        view == 1 
        ?
        <>
          <TwoFiftySix/>
        </>
        :
        view  == 2 ?
        <div style={styles.container}>
          <Uno />
        </div>
        :
        <>
          <Deuce/>
        </>
      }
    </>

  );
}

export default App;
